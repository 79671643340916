import React, { useEffect, useState, useReducer, useRef, useCallback } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import productService from '../../redux/features/product/productService';
import '../LogicTwoDashboard/LogictwoDashboard.scss';
import VehicleActivityLogs from '../../components/vehicleActivityLogs/VehicleActivityLogs';
import DeviceControl from '../../components/deviceControl/Logic2/DeviceControl';
import FeedbackMessage from '../../components/feedbackMessage/FeedbackMessage';
import GeofenceLogs from '../../components/geofenceLogs/GeofenceLogs';
import { selectTelemetryDataForDevice} from '../../redux/selectors';
import { getVehicleTelemetryDataForDevice } from '../../redux/features/product/vehicleTelemetryThunks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import VehicleEfficiency from '../../components/vehicleEfficiency/VehicleEfficiency';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import deliveryVanIconUrl from "../../assets/delivery_van_icon.png";

const { fetchAddress, sendCommandToFlespi } = productService;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const REACT_APP_FLESPI_TOKEN = process.env.REACT_APP_FLESPI_TOKEN;

const initialState = {
  currentSpeed: 0,
  vehicleIdling: false,
  vehicleParameters: [],
  address: '',
  isVehicleParked: true,
  isVehicleBlocked: false,
  acceleration: 0,
  scores: {
    accelerationScore: 100,
    brakingScore: 100,
    corneringScore: 100,
    speedingScore: 100,
  },
  doorStatus: {
    frontLeft: false,
    frontRight: false,
    rearLeft: false,
    rearRight: false,
    trunk: false,
  }
};

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_TELEMETRY':
      return { ...state, ...action.payload };
    case 'UPDATE_ADDRESS':
    case 'UPDATE_PARKED_STATUS':
    case 'TOGGLE_VEHICLE_BLOCK':
    case 'UPDATE_SCORES':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const LogicThreeDashboard = () => {
  const { imei } = useParams();
  const { deviceId } = useParams();
  const [commandStatus, setCommandStatus] = useState({
    condition1Sent: false,
    condition2Sent: false,
    condition3Sent: false,
  });  
  const [doorOpenTime, setDoorOpenTime] = useState(null);
  const [doorClosedAfterOpening, setDoorClosedAfterOpening] = useState(false);
  const [doorClosedWithinTime, setDoorClosedWithinTime] = useState(false);
  const [doorStatus, setDoorStatus] = useState({
    frontLeft: false,
    frontRight: false,
    rearLeft: false,
    rearRight: false,
    trunk: false,
  });
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [devicename, setDevicename] = useState('');
  const [commandSent, setCommandSent] = useState(false);
  const dispatch = useDispatch();
  const [telemetryData, setTelemetryData] = useState([]);
  const [state, localDispatch] = useReducer(reducer, initialState);
  const [feedback, setFeedback] = useState({ message: '', type: '' });
  const [logs, setLogs] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const markerRef = useRef(null);
  


  const handleHomeClick = () => {
    const previousPage = location.state?.from || '/devices';
    navigate(previousPage);
  };

  const fetchTelemetryData = useCallback(async (deviceId) => {
    const maxRetries = 5;

    for (let i = 0; i < maxRetries; i++) {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/flespi/gw/devices/${deviceId}/telemetry/position,can.vehicle.mileage,can.engine.rpm,can.fuel.level,can.engine.ignition.status,can.handbrake.status,can.vehicle.speed,can.front.left.door.status`, {
          headers: {
            'Authorization': `FlespiToken ${REACT_APP_FLESPI_TOKEN}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.data && response.data.result && response.data.result.length > 0) {
          const telemetry = response.data.result[0].telemetry;
          setTelemetryData(telemetry);
          console.log('Fetched telemetry data:', telemetry);
          return;
        } else {
          console.warn(`No telemetry data found for device ID: ${deviceId}`);
        }
      } catch (error) {
        if (error.response && error.response.status === 429) {
          const retryAfter = error.response.headers['retry-after']
            ? parseInt(error.response.headers['retry-after']) * 1000
            : (2 ** i) * 1000;
          console.warn(`Rate limit exceeded. Retrying after ${retryAfter} ms...`);
          await new Promise(resolve => setTimeout(resolve, retryAfter));
        } else {
          console.error('Error fetching telemetry data:', error);
          break;
        }
      }
    }
  }, []);

  useEffect(() => {
    if (deviceDetails && deviceDetails.id) {
      console.log(`IMEI in VehicleActivityLogs: ${imei}`);
      fetchTelemetryData(deviceDetails.id);
    }
  }, [imei, deviceDetails, fetchTelemetryData]);

  // Fetch all devices and map IMEI to device ID
  useEffect(() => {
    const fetchAllDevices = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/flespi/gw/devices/telemetry.channel.id=1211469`, {
          headers: {
            'Authorization': `FlespiToken ${process.env.REACT_APP_FLESPI_TOKEN}`,
            'Content-Type': 'application/json',
          },
        });

        console.log('Fetched devices:', response.data);

        if (response.data && response.data.result && response.data.result.length > 0) {
          const device = response.data.result.find(d => d.configuration.ident === deviceId);
          if (device) {
            setDeviceDetails(device);
            console.log('Device details:', device);
            if (device.name) {
              console.log('Device Name:', device.name);
              setDevicename(device.name); // Set the device name in state
            } else {
              console.warn('Device name not found');
            }
          } else {
            console.warn('Device ID not found for IMEI:', deviceId);
          }
        } else {
          console.warn('No devices found');
        }
      } catch (error) {
        console.error('Error fetching devices:', error.response?.data || error.message);
      }
    };

    fetchAllDevices();
  }, [deviceId]);

  // Fetch telemetry data for the specific device using IMEI
  useEffect(() => {
    dispatch(getVehicleTelemetryDataForDevice(deviceId));
  }, [dispatch, deviceId]);

  // Update telemetry data
  useEffect(() => {
    if (telemetryData.length > 0) {
      const latestData = telemetryData[telemetryData.length - 1];

      const newDoorStatus = {
        frontLeft: latestData['can.front.left.door.status'],
        // Update other door statuses as needed
      };

      setDoorStatus((prevState) => ({
        ...prevState,
        ...newDoorStatus,
      }));

      localDispatch({
        type: 'UPDATE_TELEMETRY',
        payload: {
          doorStatus: newDoorStatus,
        },
      });
    }
  }, [telemetryData, setDoorStatus, localDispatch]);

  // Send command using the device ID obtained from the device details
  const sendCommand = useCallback(
    async (command) => {
      console.log(`Button clicked. Sending command: ${command}`);
      if (!deviceDetails) {
        console.error('Device details not available to send command');
        return;
      }

      console.log(`Sending command to device ID: ${deviceDetails.id}`);
      
      try {
        await sendCommandToFlespi(deviceDetails.id, command, {
          properties: {},
          address: 'connection',
        });
        setFeedback({ message: `Command to ${command} sent successfully`, type: 'success' });
        console.log(`Command ${command} sent successfully to device ID: ${deviceDetails.id}`);
      } catch (error) {
        console.error(`Failed to send command to ${command}:`, error);
        setFeedback({ message: `Failed to send command to ${command}`, type: 'error' });
      } finally {
        setCommandSent(false);
      }
    },
    [deviceDetails]
  );


const doorTimerRef = useRef(null);

useEffect(() => {
  if (!telemetryData.length) return;

  const latestData = telemetryData[telemetryData.length - 1];
  const engineStatus = latestData['engine.ignition.status'];
  const currentSpeed = latestData['can.vehicle.speed'];
  const doorOpened = latestData['can.front.left.door.status'];
  const doorLocked = latestData['can.car.closed.status'];

  // Prevent unnecessary updates if the door status hasn't changed
  if (doorStatus.frontLeft !== doorOpened) {
    setDoorStatus(prevStatus => ({
      ...prevStatus,
      frontLeft: doorOpened
    }));
  }

    // Track when the door is opened with the engine off
    if (!engineStatus && doorOpened) {
      setDoorOpenTime(Date.now()); // Track the time when door was opened
    }
  
    // Manage door opening/closing events
  if (engineStatus) {
    if (doorOpened) {
      setDoorOpenTime(Date.now()); // Door is opened while engine is on
    } else if (!doorOpened && doorOpenTime) {
      // Set doorClosedAfterOpening only if the door has just transitioned from open to closed
      setDoorClosedAfterOpening(true);
      // Reset doorOpenTime to null after closing to require a new open event to trigger again
      setDoorOpenTime(null);
    }
  } else {
    // Reset the door closed flag and open time if engine is off
    setDoorClosedAfterOpening(false);
    setDoorOpenTime(null);
  }
    

    if (doorOpened) {
      setDoorOpenTime(Date.now());
      setDoorClosedWithinTime(false);
    } else {
      const timeDiff = (Date.now() - doorOpenTime) / 1000;
      if (timeDiff <= 7 && doorOpenTime) {
        setDoorClosedWithinTime(true);
      }
    }
  // Commands logic separated into its own function to be called conditionally
  const attemptToSendCommands = async () => {
    console.log('Attempting to send commands:', { engineStatus, currentSpeed, doorClosedAfterOpening, doorStatus });
  
    // Condition 1: Engine on, speed 0, door closed after opening
    if (engineStatus && currentSpeed === 0 && doorClosedAfterOpening) {
      if (!commandStatus.condition1Sent) {
        console.log('Condition 1 met and command not sent yet');
        await sendCommand('lvcanclosealldoors');
        await sendCommand('lvcanblockengine');
        setCommandStatus(prev => ({ ...prev, condition1Sent: true }));
      }
    } else {
      setCommandStatus(prev => ({ ...prev, condition1Sent: false })); // Reset if condition no longer met
    }
  
    // Condition 2: Engine on, speed 0, any door open
    if (engineStatus && currentSpeed === 0 && Object.values(doorStatus).some(status => status)) {
      if (!commandStatus.condition2Sent) {
        console.log('Condition 2 met and command not sent yet');
        await sendCommand('lvcanclosealldoors');
        await sendCommand('lvcanblockengine');
        setCommandStatus(prev => ({ ...prev, condition2Sent: true }));
      }
    } else {
      setCommandStatus(prev => ({ ...prev, condition2Sent: false }));
    }
  
        // Condition 3: Engine off, speed 0, door closed within time, door not locked
    if (!engineStatus && currentSpeed === 0 && doorClosedWithinTime && !doorLocked) {
      if (!commandStatus.condition3Sent) {
        console.log('Condition 3 met and command not sent yet');
        await sendCommand('lvcanclosealldoors');
        await sendCommand('lvcanblockengine');
        setCommandStatus(prev => ({ ...prev, condition3Sent: true }));
      }
    } else {
      setCommandStatus(prev => ({ ...prev, condition3Sent: false }));
    }
  };
  

  // Use a timeout to delay commands if needed, avoiding setting state that directly triggers re-render
  if (doorTimerRef.current) clearTimeout(doorTimerRef.current);
  doorTimerRef.current = setTimeout(attemptToSendCommands, 5000);

  return () => {
    if (doorTimerRef.current) clearTimeout(doorTimerRef.current);
  };
}, [telemetryData, doorClosedAfterOpening, doorClosedWithinTime, doorStatus, doorOpenTime, commandSent, sendCommand, commandStatus]);
 
// *******************************************GEOFENCE LOGS***************************************************************

const fetchGeofenceLogs = async (date = null) => {
  console.log('Fetching logs for date:', date); // Add this line
  try {
    const response = await axios.get(`${BACKEND_URL}/geofence-log`, {
      params: { date: date ? date.toISOString().split('T')[0] : undefined }
    });
    console.log('Logs fetched:', response.data); // Add this line
    setLogs(response.data);
  } catch (error) {
    console.error('Error fetching geofence logs:', error);
  }
};

useEffect(() => {
  fetchGeofenceLogs();
}, []);

const handleDateChange = (date) => {
  console.log('Selected date:', date); // Add this line
  fetchGeofenceLogs(date);
};
// *******************************************CLOSE FEEDBACK POP-UP***************************************************************

const closeFeedback = useCallback(() => {
  setFeedback({ message: '', type: '' });
}, []);

// *******************************************TIME INTERVAL***************************************************************

  // Refresh telemetry data at regular intervals
  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getVehicleTelemetryDataForDevice(deviceId));
    }, 10000); // Set interval to 10 seconds
    return () => clearInterval(intervalId);
  }, [dispatch, deviceId]);

  useEffect(() => {
    if (mapRef.current && !mapInstance.current) {
      mapInstance.current = L.map(mapRef.current).setView([53.4808, -2.2426], 6);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors',
      }).addTo(mapInstance.current);
    }

    if (mapInstance.current && telemetryData?.position?.value) {
      const { latitude, longitude } = telemetryData.position.value;

      if (latitude && longitude) {
        if (markerRef.current) {
          mapInstance.current.removeLayer(markerRef.current);
        }

        markerRef.current = L.marker([latitude, longitude], {
          icon: L.icon({
            iconUrl: deliveryVanIconUrl,
            iconSize: [30, 40],
            iconAnchor: [15, 40],
            popupAnchor: [0, -40],
          })
        }).addTo(mapInstance.current);

        mapInstance.current.setView([latitude, longitude], 14);
      }
    }
  }, [telemetryData]);

// ********************************************JSX**************************************************************

  return (
    <div className="dashboard2">
    <nav className="navbar">
      <div className="container --flex-between">
          <h1 className="navbar-heading">Logic-3 Dashboard of {devicename}</h1>
          <div className="navbar-links">
            <ul>
              <li>
                <button className="btn-primary-home" onClick={handleHomeClick}>
                  <span className="icon"><FontAwesomeIcon icon={faHome} /></span>
                  <span className="text">Go back to Homepage</span>
                </button>
              </li>
              {/* Other links */}
            </ul>
          </div>
      </div>
    </nav>
    <br></br>
      <div className="grid-container">
        <div className='right'>
          <VehicleActivityLogs telemetryData={telemetryData} 
            fetchAddress={fetchAddress} 
            doorStatus={state.doorStatus} 
            deviceDetails={deviceDetails} // Pass deviceDetails here
            deviceId={deviceId} 
          />
          {/* <GeofenceLogs logs={logs} onDateChange={handleDateChange} /> */}
        </div>  
        <div className="center-column">
          <div className='control'>
            <DeviceControl sendCommand={sendCommand}/>
            <FeedbackMessage feedback={feedback} closeFeedback={closeFeedback} />
          </div>
          <div id="mapp" ref={mapRef} className="map-containerr"></div>
        </div>
        
        <div className='left'>
        {deviceDetails && (
          <VehicleEfficiency 
            deviceId={deviceDetails.id}
            telemetryData={telemetryData}
            imei={imei}
          />
        )}
        </div>  
      </div>  
  </div>
  );
};

export default LogicThreeDashboard;
