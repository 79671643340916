import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import logo from "../../assets/AR logo.png";
import "../GeofenceList.js/GeofenceList.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const REACT_APP_FLESPI_TOKEN = process.env.REACT_APP_FLESPI_TOKEN;

const GeofenceListPage = () => {
  const [geofences, setGeofences] = useState([]);
  const [geofenceLogs, setGeofenceLogs] = useState([]);
  const [selectedGeofence, setSelectedGeofence] = useState(null);
  const [editingGeofence, setEditingGeofence] = useState(null);
  const [updatedCoordinates, setUpdatedCoordinates] = useState('');
  const [vehiclePosition, setVehiclePosition] = useState(null);
  const [currentGeofence, setCurrentGeofence] = useState(null);
  const [enterTimestamp, setEnterTimestamp] = useState(null);
  const [showNoLogs, setShowNoLogs] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const lastEventType = useRef(null);
  const insideGeofence = useRef(false);

  // Fetch geofences from the backend
  const fetchGeofences = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/deviceGeofences`);
      setGeofences(response.data);
    } catch (error) {
      console.error('Error fetching geofences:', error);
    }
  };

  // Fetch geofence logs for a specific device
  const fetchGeofenceLogs = async (deviceName) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/devicegeofenceLogs/${deviceName}`);
      setGeofenceLogs(response.data);
      setShowNoLogs(response.data.length === 0);
    } catch (error) {
      console.error('Error fetching geofence logs:', error);
    }
  };

  // Fetch real-time telemetry data using deviceId to get accurate vehicle position
  const fetchTelemetryData = useCallback(async (deviceId) => {
    try {
      const response = await axios.get(
        `${BACKEND_URL}/api/flespi/gw/devices/${deviceId}/telemetry/position`,
        {
          headers: {
            'Authorization': `FlespiToken ${REACT_APP_FLESPI_TOKEN}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data && response.data.result && response.data.result.length > 0) {
        const telemetry = response.data.result[0].telemetry;
        setVehiclePosition([telemetry.position.value.longitude, telemetry.position.value.latitude]);
        console.log('Fetched telemetry data:', telemetry);
      } else {
        console.warn(`No telemetry data found for device ID: ${deviceId}`);
      }
    } catch (error) {
      console.error('Error fetching telemetry data:', error);
    }
  }, []);

  // Fetch geofences on component mount
  useEffect(() => {
    fetchGeofences();
  }, []);

  // Handle viewing logs and fetching telemetry data for the selected geofence
  const handleViewLogs = (geofence) => {
    setSelectedGeofence(geofence);
    if (geofence.deviceId) {
      fetchTelemetryData(geofence.deviceId);
    }
    fetchGeofenceLogs(geofence.deviceName);
  };

  // Handle geofence editing
  const handleEdit = (geofence) => {
    setEditingGeofence(geofence);
    setUpdatedCoordinates(JSON.stringify(geofence.geometry.coordinates));
  };

  // Handle geofence update
  const handleUpdate = async () => {
    try {
      const updatedGeofence = {
        ...editingGeofence,
        geometry: {
          ...editingGeofence.geometry,
          coordinates: JSON.parse(updatedCoordinates),
        },
      };

      await axios.put(`${BACKEND_URL}/api/deviceGeofences/${editingGeofence._id}`, updatedGeofence);
      setEditingGeofence(null);
      fetchGeofences(); // Refresh the list after update
    } catch (error) {
      console.error('Error updating geofence:', error);
    }
  };

  // Function to delete a geofence
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BACKEND_URL}/api/deviceGeofences/${id}`);
      fetchGeofences(); // Refresh the list
    } catch (error) {
      console.error('Error deleting geofence:', error);
    }
  };

  // Function to check if a point is inside a polygon using Ray-Casting algorithm
  const isPointInPolygon = (point, vs) => {
    const [x, y] = point;
    let inside = false;

    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      const [xi, yi] = vs[i];
      const [xj, yj] = vs[j];
      const intersect = ((yi > y) !== (yj > y)) && x < (xj - xi) * (y - yi) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  };

  // Check if the vehicle is inside the geofence
  const checkGeofence = useCallback(() => {
    if (!vehiclePosition || vehiclePosition.length !== 2) {
      console.warn('Vehicle position is not defined or incomplete.');
      return;
    }

    geofences.forEach((geofence) => {
      const polygon = geofence.geometry.coordinates[0]; // Get the polygon coordinates

      if (!polygon || polygon.length === 0) {
        console.warn('Geofence coordinates are not properly defined:', geofence);
        return;
      }

      // Check if the vehicle position is inside the polygon
      const isInside = isPointInPolygon(vehiclePosition, polygon);

      if (isInside && !insideGeofence.current) {
        insideGeofence.current = true;
        setCurrentGeofence(geofence);
        setEnterTimestamp(new Date());
        lastEventType.current = 'ENTERED';

        // Log entry event
        axios.post(`${BACKEND_URL}/api/devicegeofenceLogs`, {
          deviceId: geofence.deviceId,
          deviceName: geofence.deviceName,
          geofenceId: geofence._id,
          eventType: 'ENTERED',
          timestamp: new Date(),
        }).catch(error => console.error('Error logging entry event:', error));
      } else if (!isInside && insideGeofence.current && currentGeofence && currentGeofence._id === geofence._id) {
        insideGeofence.current = false;
        const exitTimestamp = new Date();
        const duration = exitTimestamp - enterTimestamp;
        setCurrentGeofence(null);
        setEnterTimestamp(null);
        lastEventType.current = 'EXITED';

        // Log exit event
        axios.post(`${BACKEND_URL}/api/devicegeofenceLogs`, {
          deviceId: geofence.deviceId,
          deviceName: geofence.deviceName,
          geofenceId: geofence._id,
          eventType: 'EXITED',
          timestamp: exitTimestamp,
          duration: duration,
        }).catch(error => console.error('Error logging exit event:', error));
      }
    });
  }, [vehiclePosition, geofences, currentGeofence, enterTimestamp]);

  // Check geofence periodically
  useEffect(() => {
    const interval = setInterval(checkGeofence, 5000);
    return () => clearInterval(interval);
  }, [checkGeofence]);

  return (
    <div className="geofence-list-page">
      <nav className="navbar">
        <div className="container --flex-between">
          <div className="navbar-brand">
            <img src={logo} alt="Company Logo" />
            <span>AR Experts LTD</span>
          </div>
          <h1 className="navbar-heading">Geofence Manager</h1>
          <div className="navbar-links">
            <ul>
              <li>
                <button className="btn-primary-home" onClick={() => navigate(location.state?.from || '/home')}>
                  <span className="icon"><FontAwesomeIcon icon={faHome} /></span>
                  <span className="text">Home</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="geofence-cards">
        <div className="geofence-list-card">
          <h3>List of Geofences</h3>
          <ul>
            {geofences.map(geofence => (
              <li key={geofence._id}>
                <h4>{geofence.deviceName}</h4>
                <p>Coordinates: {JSON.stringify(geofence.geometry.coordinates)}</p>
                <div className='action-buttons'>
                  <button onClick={() => handleEdit(geofence)}>Edit</button>
                  <button onClick={() => handleDelete(geofence._id)}>Delete</button>
                  <button onClick={() => handleViewLogs(geofence)}>View Logs</button>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="geofence-log-card">
          <h3>Geofence Logs</h3>
          {selectedGeofence && (
            <div className='geologs'>
              <h4>Logs for {selectedGeofence.deviceName}</h4>
              {geofenceLogs.length > 0 ? (
                <ul>
                  {geofenceLogs.map(log => (
                    <li key={log._id}>
                      The vehicle {log.deviceName} {log.eventType} this geofence at {new Date(log.timestamp).toLocaleString()}
                    </li>
                  ))}
                </ul>
              ) : showNoLogs && (
                <div className='no-logs-popup'>No Logs found</div>
              )}
            </div>
          )}
        </div>
      </div>

      {editingGeofence && (
        <div className="form-popup activee">
          <h3>Edit Geofence</h3>
          <label>
            Coordinates :
          </label>
          <textarea
            value={updatedCoordinates}
            onChange={(e) => setUpdatedCoordinates(e.target.value)}
          />
          <div className='btn-container'>
            <button onClick={handleUpdate}>Update</button>
            <button onClick={() => setEditingGeofence(null)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GeofenceListPage;
