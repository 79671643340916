import React, { useState, useRef, useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';
import axios from 'axios';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const CreateGeofenceForm = ({ deviceDetails, onSubmit, onClose }) => {
    const mapRef = useRef(null);
    const mapInstance = useRef(null);
    const drawnItems = useRef(new L.FeatureGroup());
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        if (mapRef.current && !mapInstance.current) {
            mapInstance.current = L.map(mapRef.current, {
                center: [51.505, -0.09], // Default center
                zoom: 13,
                layers: [
                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    }),
                    drawnItems.current
                ]
            });

            const drawControl = new L.Control.Draw({
                edit: {
                    featureGroup: drawnItems.current
                },
                draw: {
                    polygon: true,
                    rectangle: true,
                    circle: true,
                    polyline: false,
                    marker: false
                }
            });
            mapInstance.current.addControl(drawControl);

            mapInstance.current.on(L.Draw.Event.CREATED, function (event) {
                const layer = event.layer;
                drawnItems.current.addLayer(layer);
            });
        }
    }, []);

    const handleDeviceSelection = (deviceId) => {
        setSelectedDevices(prevSelectedDevices =>
            prevSelectedDevices.includes(deviceId)
                ? prevSelectedDevices.filter(id => id !== deviceId)
                : [...prevSelectedDevices, deviceId]
        );
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedDevices([]); // Deselect all
        } else {
            setSelectedDevices(Object.keys(deviceDetails)); // Select all
        }
        setSelectAll(!selectAll);
    };

    const handleSaveGeofence = async (e) => {
        e.preventDefault();

        if (selectedDevices.length === 0) {
            alert("Please select at least one device.");
            return;
        }

        const drawnLayers = [];
        drawnItems.current.eachLayer(layer => {
            const geoJSON = layer.toGeoJSON();
            drawnLayers.push(geoJSON.geometry);  // Push the geometry object to the array
        });

        if (drawnLayers.length === 0) {
            alert("No geofence area defined. Please draw a geofence on the map.");
            return;
        }

        const geofences = selectedDevices.map(deviceId => {
            const device = deviceDetails[deviceId];
            return {
                deviceId,
                deviceName: device ? device.name : 'Unknown Device', // Include device name
                geometry: {
                    type: 'Polygon',  // Ensure the type is explicitly set to "Polygon"
                    coordinates: drawnLayers[0].coordinates  // Use the first drawn layer's coordinates
                }
            };
        });

        try {
            const response = await axios.post(`${BACKEND_URL}/api/deviceGeofences`, geofences);
            console.log('Geofences saved:', response.data);
            onSubmit(response.data);
        } catch (error) {
            console.error('Error saving geofences:', error);
        }
    };

    return (
        <div className="form-popup activeee">
            <div className="map-container" ref={mapRef} style={{ height: '300px', width: '100%', marginBottom: '20px' }}></div>
            <form onSubmit={handleSaveGeofence}>
                <h2>Select Devices:</h2>
                <ul className='device-list'>
                <label>
                    <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                    />
                    Select All
                </label> <br></br>
                    {Object.entries(deviceDetails).map(([id, device]) => (
                        <li key={id}>
                            <label>
                                <input
                                    type="checkbox"
                                    value={id}
                                    checked={selectedDevices.includes(id)}
                                    onChange={() => handleDeviceSelection(id)}
                                />
                                {device.name}
                            </label>
                        </li>
                    ))}
                </ul>
                <button type="submit" className="btn-primary">Save Geofence</button>
                <button type="button" className="close-btn" onClick={onClose}>Close</button>
            </form>
        </div>
    );
};

export default CreateGeofenceForm;
