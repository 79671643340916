import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const CreateClientUserForm = ({ userId, deviceDetails, onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    deviceIds: [],
    logics: []
  });
  const [assignedLogics, setAssignedLogics] = useState([]);
  const [selectAllDevices, setSelectAllDevices] = useState(false);

  useEffect(() => {
    const fetchAssignedLogics = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/users/${userId}/logics`);
        setAssignedLogics(response.data);
      } catch (error) {
        console.error("Error fetching assigned logics:", error);
      }
    };

    fetchAssignedLogics();
  }, [userId]);

  const handleDeviceSelection = (deviceId) => {
    setFormData(prevFormData => {
      const updatedDeviceIds = prevFormData.deviceIds.includes(deviceId)
        ? prevFormData.deviceIds.filter(id => id !== deviceId)
        : [...prevFormData.deviceIds, deviceId];

      setSelectAllDevices(updatedDeviceIds.length === Object.keys(deviceDetails).length);
      return { ...prevFormData, deviceIds: updatedDeviceIds };
    });
  };

  const handleSelectAllDevicesChange = () => {
    if (selectAllDevices) {
      setFormData(prevFormData => ({ ...prevFormData, deviceIds: [] }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        deviceIds: Object.keys(deviceDetails)
      }));
    }
    setSelectAllDevices(!selectAllDevices);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    onSubmit(formData);
  };

  return (
    <div className="form-popup activee">
      <form onSubmit={handleSubmit}>
        <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
        <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
        <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required />
        <input type="password" name="confirmPassword" placeholder="Confirm Password" value={formData.confirmPassword} onChange={handleChange} required />

        <div className="checkbox-group">
          Devices:
          <label>
            <input
              type="checkbox"
              checked={selectAllDevices}
              onChange={handleSelectAllDevicesChange}
            />
            Select All
          </label>
          {Object.entries(deviceDetails).map(([id, device]) => (
            <label key={id}>
              <input
                type="checkbox"
                value={id}
                checked={formData.deviceIds.includes(id)}
                onChange={() => handleDeviceSelection(id)}
              />
              {device.name}
            </label>
          ))}
        </div>

        <div className="checkbox-group">
          Logics:
          {assignedLogics.length > 0 ? (
            assignedLogics.map((logic, index) => (
              <label key={index}>
                <input
                  type="checkbox"
                  name="logics"
                  value={logic}
                  onChange={handleChange}
                />
                {logic}
              </label>
            ))
          ) : (
            <p>No logics available for this user.</p>
          )}
        </div>

        <button type="submit" className="btn-primary">Create User</button>
        <button type="button" className="close-btn" onClick={onClose}>Close</button>
      </form>
    </div>
  );
};

CreateClientUserForm.propTypes = {
  userId: PropTypes.string.isRequired,
  deviceDetails: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateClientUserForm;
