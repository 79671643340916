import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import Login from "./pages/auth/Login";
// import Register from "./pages/auth/Register";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";
import Dashboard from "./pages/dashboard/Dashboard";
import Sidebar from "./components/sidebar/Sidebar";
import Layout from "./components/layout/Layout";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { getLoginStatus } from "./services/authService";
import { SET_LOGIN } from "./redux/features/auth/authSlice";
import Contact from "./pages/contact/Contact";
import History from "./pages/history/History";
// import GeofenceManager from "./pages/Geofence/GeofenceManager";
import Program from "./pages/program/Program";
import Certification from "./pages/Certification/Certification";
import LogicOneDashboard from "./pages/LogicOneDashboard/LogiconeDashboard";
import LogicTwoDashboard from "./pages/LogicTwoDashboard/LogictwoDashboard";
import LogicThreeDashboard from "./pages/LogicThreeDashboard/LogicThreeDashboard";
import AssignedDevices from "./pages/AssignedDevices/AssignedDevices";
import Protector from "./components/protect/Protector";
import ClientUserDevices from "./pages/clientUserDevices/ClientUserDevices";
import GeofenceListPage from "./pages/GeofenceList.js/GeofenceList";
import MqttDataDisplay from "./components/mqtt";

axios.defaults.withCredentials = true;

function App() {
  const dispatch = useDispatch();
  const flespiToken = process.env.REACT_APP_FLESPI_TOKEN;
  useEffect(() => {
    async function loginStatus() {
      const status = await getLoginStatus();
      dispatch(SET_LOGIN(status));
    }
    loginStatus();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/devices" element={<Protector><Home /></Protector>} />
        <Route path="/assigned-devices" element={<Protector><AssignedDevices /></Protector>} />
        <Route path="/client-user-devices" element={<Protector><ClientUserDevices /></Protector>} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/resetpassword/:resetToken" element={<Reset />} />
        <Route path="/resetpassword/:resetToken" element={<Reset />} />
        <Route path="/mqtt" element={<MqttDataDisplay  token={flespiToken}/>} />
       
        <Route
          path="/dashboard/:imei"
          element={
            <Protector>
                <Layout>
                  <Dashboard />
                </Layout>
            </Protector>
          }
        />
        <Route
          path="/logiconedashboard/:deviceId"
          element={
            <Protector>
                <Layout>
                  <LogicOneDashboard />
                </Layout>
            </Protector>
          }
        />
        <Route
          path="/logictwodashboard/:deviceId"
          element={
            <Protector>
                <Layout>
                  <LogicTwoDashboard />
                </Layout>
            </Protector>
          }
        />
        <Route
          path="/logicthreedashboard/:deviceId"
          element={
            <Protector>
                <Layout>
                  <LogicThreeDashboard />
                </Layout>
            </Protector>
          }
        />
        <Route
          path="/data-and-history"
          element={
            <Protector>
                <Layout>
                  <History />
                </Layout>
            </Protector>
          }
        />
        <Route
          path="/geofencing"
          element={
            <Protector>
                <Layout>
                  {/* <GeofenceManager /> */}
                  <GeofenceListPage />
                </Layout>
            </Protector>
          }
        />
        <Route
          path="/program"
          element={
            <Protector>
              <Sidebar>
                <Layout>
                  <Program />
                </Layout>
              </Sidebar>
            </Protector>
          }
        />
        <Route
          path="/certification"
          element={
            <Protector>
              <Sidebar>
                <Layout>
                  <Certification />
                </Layout>
              </Sidebar>
            </Protector>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Protector>
                <Layout>
                  <Contact />
                </Layout>
            </Protector>
          }
        />
        {/* Catch-all route to redirect to homepage */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;