import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import UserList from '../../components/users/UserList';
import CreateGeofenceForm from '../../components/deviceList/CreateGeofenceForm'; // Import the CreateGeofenceForm component
import productService from "../../redux/features/product/productService";

const { fetchAddress } = productService;

const DeviceList = ({
  vehicleData,
  deviceDetails,
  showCreateUserForm,
  showAddDeviceForm,
  showUserList,
  createUserFormData,
  addDeviceFormData,
  selectedVehicle,
  selectedLogic,
  loading,
  error,
  toggleCreateUserForm,
  toggleAddDeviceForm,
  handleCreateUserInputChange,
  handleCreateUserCheckboxChange,
  handleCreateUserLogicChange,
  handleCreateUserFormSubmit,
  closeCreateUserForm,
  closeAddDeviceForm,
  handleAddDeviceInputChange,
  handleAddDeviceFormSubmit,
  flyToVehicle,
  handleLogicSelection,
  setShowUserList,
}) => {
  const [addressData, setAddressData] = useState({});
  const [showCreateGeofenceForm, setShowCreateGeofenceForm] = useState(false); // New state for geofence form
  const addressDataRef = useRef(addressData);
  const [searchQuery, setSearchQuery] = useState(''); // State for search query

  useEffect(() => {
    addressDataRef.current = addressData;
  }, [addressData]);

  const fetchAllAddresses = useCallback(async () => {
    const updatedAddressData = {};

    for (const vehicle of vehicleData) {
      const { imei, longitude, latitude } = vehicle;
      if (longitude && latitude && !addressDataRef.current[imei]) {
        const address = await fetchAddress(latitude, longitude);
        updatedAddressData[imei] = address;
      }
    }

    setAddressData(prevData => ({
      ...prevData,
      ...updatedAddressData
    }));
  }, [vehicleData]);

  useEffect(() => {
    fetchAllAddresses();
  }, [fetchAllAddresses]);

  // Filtered vehicle data based on search query
  const filteredVehicles = vehicleData.filter(vehicle =>
    vehicle.deviceName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="store-list">
      <div className="heading">
        <h2>List of Devices</h2>
      </div>
      <div className='search-container-home'>
        <input
          type="text"
          placeholder="Search devices by name..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
        <i className="fas fa-search search-icon"></i>
        <button className="btn-primary-custom create" onClick={toggleCreateUserForm}><i className="fas fa-user-plus"></i></button> 
      </div>
      <div className='button-container-home'>
        <button className="btn-primary-custom " onClick={toggleAddDeviceForm}>Add Device</button>
        <button className="btn-primary-custom " onClick={() => setShowUserList(!showUserList)}>Users</button>
        <button className="btn-primary-custom " onClick={() => setShowCreateGeofenceForm(true)}>Create Geofence</button>
      </div>
      {showUserList && <UserList />}
      
      {showAddDeviceForm && (
        <div className="form-popup activee">
          <form onSubmit={handleAddDeviceFormSubmit}>
            <input type="text" name="CompanyName" placeholder="Company Name" value={addDeviceFormData.CompanyName} onChange={handleAddDeviceInputChange} required />
            <input type="text" name="vehicleNumberPlate" placeholder="Vehicle Number Plate" value={addDeviceFormData.vehicleNumberPlate} onChange={handleAddDeviceInputChange} required />
            <input type="text" name="imeiNumber" placeholder="IMEI Number" value={addDeviceFormData.imeiNumber} onChange={handleAddDeviceInputChange} required />
            <button type="submit" className="btn-primary">Add Device</button>
            <button type="button" className="close-btn" onClick={closeAddDeviceForm}>Close</button>
          </form>
        </div>
      )}

      {showAddDeviceForm && <div className="overlay active" onClick={closeAddDeviceForm}></div>}
      {showCreateGeofenceForm && (
        <CreateGeofenceForm
          deviceDetails={deviceDetails}
          onSubmit={(data) => {
            console.log('Geofence data:', data);
            setShowCreateGeofenceForm(false);
          }}
          onClose={() => setShowCreateGeofenceForm(false)}
        />
      )}
      {loading && <p>Loading...</p>}
      {error && <p>Error loading data: {error}</p>}

      {!loading && !error && (
        <ul className="list">
          {filteredVehicles.map((vehicle, index) => (
            <li key={index} onClick={() => flyToVehicle(vehicle)}>
              <div className="shop-item">
                <button className="link-button">{vehicle.deviceName}</button>
                <div className="vehicle-info">
                  <p><strong>IMEI:</strong> {vehicle.imei}</p>
                  <p><strong>Location:</strong> {addressData[vehicle.imei] || 'Fetching address...'}</p>
                </div>
                {selectedVehicle === vehicle && (
                  <div className="logic-selection">
                    <select onChange={handleLogicSelection} value={selectedLogic || ''}>
                      <option value="" disabled>Select Logic</option>
                      <option value="logic1">Logic 1</option>
                      <option value="logic2">Logic 2</option>
                      <option value="logic3">Logic 3</option>
                      <option value="logic4">Logic 4</option>
                    </select>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}

    </div>
  );
};

DeviceList.propTypes = {
  vehicleData: PropTypes.array.isRequired,
  deviceDetails: PropTypes.object.isRequired,
  showCreateUserForm: PropTypes.bool.isRequired,
  showAddDeviceForm: PropTypes.bool.isRequired,
  showUserList: PropTypes.bool.isRequired,
  createUserFormData: PropTypes.object.isRequired,
  addDeviceFormData: PropTypes.object.isRequired,
  selectedVehicle: PropTypes.object,
  selectedLogic: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  toggleCreateUserForm: PropTypes.func.isRequired,
  toggleAddDeviceForm: PropTypes.func.isRequired,
  handleCreateUserInputChange: PropTypes.func.isRequired,
  handleCreateUserCheckboxChange: PropTypes.func.isRequired,
  handleCreateUserLogicChange: PropTypes.func.isRequired,
  handleCreateUserFormSubmit: PropTypes.func.isRequired,
  closeCreateUserForm: PropTypes.func.isRequired,
  closeAddDeviceForm: PropTypes.func.isRequired,
  handleAddDeviceInputChange: PropTypes.func.isRequired,
  handleAddDeviceFormSubmit: PropTypes.func.isRequired,
  flyToVehicle: PropTypes.func.isRequired,
  handleLogicSelection: PropTypes.func.isRequired,
  setShowUserList: PropTypes.func.isRequired,
};

export default DeviceList;
