import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import FeedbackMessage from '../feedbackMessage/FeedbackMessage'; // Import the FeedbackMessage component
import './DeviceControl.scss';

const DeviceControl = ({ sendCommand, toggleBlockVehicle, isVehicleBlocked, feedback, closeFeedback }) => {
  
  // New function to handle blocking both engine and accessory cutoff
  const blockVehicle = () => {
    sendCommand('lvcanblockengine'); // Send Block Engine command
    toggleBlockVehicle(); // Trigger accessory cutoff command
  };

  return (
    <div className="card device-control-container">
      <h2 className="head">Tracker Commands</h2>
      <div className="device-control-list">
        <button className="device-control-button lock-btn" onClick={() => sendCommand('lvcanclosealldoors')}>Lock All Doors</button>
        <button className="device-control-button unlock-btn" onClick={() => sendCommand('lvcanopenalldoors')}>Unlock All Doors</button>
        <button className="device-control-button block-btn" onClick={() => sendCommand('lvcanblockengine')}>Block Engine</button>
        <button className="device-control-button unblock-btn" onClick={() => sendCommand('lvcanunblockengine')}>Unblock Engine</button>
        {/* New Block Vehicle Button */}
        <button className="device-control-button toggle-block-btn" onClick={toggleBlockVehicle}>
          {isVehicleBlocked ? 'Enable Acc' : 'Acc CutOff'}
        </button>
        <button className="device-control-button block-vehicle-btn" onClick={blockVehicle}>Block Vehicle</button>
        {/* Incorporate FeedbackMessage component directly */}
        <FeedbackMessage feedback={feedback} closeFeedback={closeFeedback} />
      </div>
    </div>
  );
};

export default React.memo(DeviceControl);
