import axios from "axios";
import { fetchStart, fetchSuccess, fetchFailure } from './vehicleTelemetryActions';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const FLESPI_TOKEN = process.env.REACT_APP_FLESPI_TOKEN;

const axiosInstance = axios.create({
  headers: {
    'Authorization': `FlespiToken ${FLESPI_TOKEN}`,
    'Content-Type': 'application/json',
  }
});

const cache = new Map();
let lastFetchedTime = null; // Keep track of the last fetched time

const fetchWithExponentialBackoff = async (url, options = {}, maxRetries = 5) => {
  let retryCount = 0;

  while (retryCount < maxRetries) {
    try {
      const response = await axiosInstance(url, options);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 429) {
        const retryAfter = error.response.headers['retry-after']
          ? parseInt(error.response.headers['retry-after']) * 1000
          : (2 ** retryCount) * 1000;
        console.warn(`Rate limit exceeded. Retrying after ${retryAfter} ms...`);
        await new Promise(resolve => setTimeout(resolve, retryAfter));
        retryCount++;
      } else {
        throw error;
      }
    }
  }
  throw new Error('Max retries reached. Could not fetch data.');
};

const getTelemetryData = async () => {
  const startTime = lastFetchedTime || (Date.now() - 60 * 60 * 1000); // Default to last 1 hour if no previous data
  const endTime = Date.now(); // Current time

  const cacheKey = `${BACKEND_URL}/api/flespi/gw/channels/1211469/messages?data=%7B%7D'`;
  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }
console.log(cacheKey);

  const data = await fetchWithExponentialBackoff(cacheKey, {
    params: {
      'data.gw.time': `${startTime},${endTime}`
    }
  });
  if (data && data.result && Array.isArray(data.result)) {
    cache.set(cacheKey, data.result);
    lastFetchedTime = endTime; // Update last fetched time
    return data.result;
  }
  throw new Error('Invalid data format');
};

export const getVehicleTelemetryData = () => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const vehiclesData = await getTelemetryData();
    dispatch(fetchSuccess(vehiclesData));
    console.log(vehiclesData);
    
  } catch (error) {
    dispatch(fetchFailure(error.message));
    console.error('Failed to fetch vehicle telemetry data:', error);
  }
};

const getTelemetryDataForDevice = async (imei) => {
  const startTime = lastFetchedTime || (Date.now() - 60 * 60 * 1000); // Default to last 1 hour if no previous data
  const endTime = Date.now(); // Current time

  const cacheKey = `${BACKEND_URL}/api/flespi/gw/channels/1211469/messages?data=%7B%7D'`;
  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }

  const data = await fetchWithExponentialBackoff(`${BACKEND_URL}/api/flespi/gw/channels/1211469/messages?data=%7B%7D'`, {
    params: {
      'data.ident': imei,
      'data.gw.time': `${startTime},${endTime}`
    }
  });
  if (data && data.result && Array.isArray(data.result)) {
    cache.set(cacheKey, data.result);
    lastFetchedTime = endTime; // Update last fetched time
    return data.result;
  }
  throw new Error('Invalid data format');
};

export const getVehicleTelemetryDataForDevice = (imei) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const vehicleData = await getTelemetryDataForDevice(imei);
    dispatch(fetchSuccess(vehicleData));
  } catch (error) {
    dispatch(fetchFailure(error.message));
    console.error('Failed to fetch vehicle telemetry data for device:', error);
  }
};

// Function to get a formatted address using OpenStreetMap's Nominatim service
const fetchAddress = async (latitude, longitude) => {
  try {
    // Construct the Nominatim reverse geocoding URL
    const nominatimUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
    const response = await axiosInstance.get(nominatimUrl);
    
    if (response.data && response.data.display_name) {
      return response.data.display_name; // Return the human-readable address
    } else {
      return 'Address not found';
    }
  } catch (error) {
    console.error('Geocoding error:', error.message);
    return 'Unable to fetch address';
  }
};

// Function to fetch multiple addresses (or formatted URL if required)
const fetchAddresses = async (latitude, longitude) => {
  try {
    // Construct the Nominatim reverse geocoding URL for the given latitude and longitude
    const nominatimUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
    const response = await axiosInstance.get(nominatimUrl);

    if (response.data && response.data.display_name) {
      return response.data.display_name; // Return the formatted address
    } else {
      return 'No address found';
    }
  } catch (error) {
    console.error('Error fetchingg address:', error.message);
    return 'Address not found';
  }
};

const sendCommandToFlespi = async (deviceId, command, data) => {
  const maxRetries = 5;
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const axiosInstance = axios.create({
    timeout: 30000, // 30 seconds
    headers: {
      'Authorization': `FlespiToken ${process.env.REACT_APP_FLESPI_TOKEN}`,
      'Content-Type': 'application/json',
    },
    withCredentials: false, // Disable credentials for CORS requests
  });

  for (let attempt = 0; attempt < maxRetries; attempt++) {
    try {
      const flespiUrl = `https://flespi.io/gw/devices/${deviceId}/settings/${command}`;
      const response = await axiosInstance.put(flespiUrl, data);
        console.log(`Command sent successfully:`, response.data);
      return response.data;
    } catch (error) {
      console.error(`Error sending command to Flespi (attempt ${attempt + 1}):`, error.message, {
        config: error.config,
        request: error.request,
        response: error.response ? {
          status: error.response.status,
          data: error.response.data,
        } : null,
      });
      if (attempt < maxRetries - 1) {
        const backoffDelay = Math.pow(2, attempt) * 1000; // Exponential backoff
        console.log(`Retrying in ${backoffDelay} ms...`);
        await delay(backoffDelay);
      } else {
        throw new Error(`Max retries reached. Failed to send command to Flespi: ${error.message}`);
      }
    }
  }
};

const productService = {
  getVehicleTelemetryData,
  fetchAddress,
  sendCommandToFlespi,
  fetchAddresses,
  getVehicleTelemetryDataForDevice
};

export default productService;