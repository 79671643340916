import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import EditUser from './EditUser'; // Assume you have an EditUser component similar to UserList
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ClientUserList = ({ onClose }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingUserId, setEditingUserId] = useState(null);

  useEffect(() => {
    const fetchClientUsers = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/users/client-users`, {
          withCredentials: true // Ensure cookies are sent with the request
        });
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching client users:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchClientUsers();
  }, []);

  const handleDelete = async (userId) => {
    try {
      await axios.delete(`${BACKEND_URL}/api/users/${userId}`);
      setUsers(users.filter(user => user._id !== userId));
    } catch (error) {
      console.error('Failed to delete user', error);
      alert('Failed to delete user');
    }
  };

  const handleEdit = (userId) => {
    setEditingUserId(userId);
  };

  const handleCloseEdit = () => {
    setEditingUserId(null);
  };

  const handleUserUpdated = (updatedUser) => {
    setUsers(users.map(user => (user._id === updatedUser._id ? updatedUser : user)));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading users: {error.message}</div>;
  }

  return (
    <div className="user-popup activeee">
      <div className="popup-content">
        <h2>Client Users List</h2>
        <ul>
          {users.map((user) => (
            <li key={user._id}>
              <p>Name: {user.name}</p>
              <p>Email: {user.email}</p>
              <div className="btn-container">
                <button className="edit-btn" onClick={() => handleEdit(user._id)}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                </button>
                <button className="delete-btn" onClick={() => handleDelete(user._id)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {editingUserId && (
        <EditUser
          userId={editingUserId}
          onClose={handleCloseEdit}
          onUserUpdated={handleUserUpdated}
        />
      )}
      <button type="button" className="close-btn" onClick={onClose}>Close</button>
    </div>
  );
};

export default ClientUserList;
