import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import './VehicleEfficiencyNew.scss';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const REACT_APP_FLESPI_TOKEN = process.env.REACT_APP_FLESPI_TOKEN;

const fetchTelemetryData = async (deviceId) => {
  const maxRetries = 5;

  for (let i = 0; i < maxRetries; i++) {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/flespi/gw/devices/${deviceId}/telemetry/position,can.vehicle.mileage,can.engine.rpm,can.fuel.level,can.fuel.volume,can.engine.ignition.status,can.handbrake.status,can.vehicle.speed`, {
        headers: {
          'Authorization': `FlespiToken ${REACT_APP_FLESPI_TOKEN}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data && response.data.result && response.data.result.length > 0) {
        const telemetry = response.data.result[0].telemetry;
        console.log('Fetched telemetry data:', telemetry);
        return telemetry;
      } else {
        console.warn(`No telemetry data found for device ID: ${deviceId}`);
        return null;
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        const retryAfter = error.response.headers['retry-after']
          ? parseInt(error.response.headers['retry-after']) * 1000
          : (2 ** i) * 1000;
        console.warn(`Rate limit exceeded. Retrying after ${retryAfter} ms...`);
        await new Promise(resolve => setTimeout(resolve, retryAfter));
      } else {
        console.error('Error fetching telemetry data:', error);
        return null;
      }
    }
  }
  return null;
};

const Gauge = ({ value, label, max = 100 }) => {
  const needleStyle = {
    transform: `rotate(${(value / max) * 180 - 90}deg)`,
    transformOrigin: '50% 100%',
  };
  const gradientId = `gradient-${label.replace(/\s+/g, '-')}`;

  console.log(`Rendering Gauge for ${label}:`, value);

  return (
    <div className="gauge">
      <svg viewBox="0 0 100 50" className="gauge__svg">
        <defs>
          <linearGradient id={gradientId}>
            <stop offset="0%" stopColor="#9b59b6" />
            <stop offset="100%" stopColor="#e74c3c" />
          </linearGradient>
        </defs>
        <path
          className="gauge__background"
          d="M10 50 A 40 40 0 0 1 90 50"
          stroke="#333"
          strokeWidth="10"
          fill="none"
        />
        <path
          className="gauge__fill"
          d="M10 50 A 40 40 0 0 1 90 50"
          stroke={`url(#${gradientId})`}
          strokeWidth="10"
          fill="none"
          style={{ strokeDasharray: `${(value / max) * 126}, 126` }}
        />
        <line
          className="gauge__needle"
          x1="50"
          y1="50"
          x2="50"
          y2="10"
          stroke="#fff"
          strokeWidth="3"
          style={needleStyle}
        />
        <circle cx="50" cy="50" r="3" fill="#fff" />
      </svg>
      <div className="gauge__value">{value}</div>
      <div className="gauge__label">{label}</div>
    </div>
  );
};

const MileageGauge = ({ value, label }) => {
  const needleStyle = {
    transform: `rotate(${(value / 100000) * 180 - 90}deg)`,
    transformOrigin: '50% 100%',
  };
  const gradientId = `gradient-${label.replace(/\s+/g, '-')}`;

  console.log(`Rendering Mileage Gauge for ${label}:`, value);

  return (
    <div className="gauge">
      <svg viewBox="0 0 100 50" className="gauge__svg">
        <defs>
          <linearGradient id={gradientId}>
            <stop offset="0%" stopColor="#9b59b6" />
            <stop offset="100%" stopColor="#e74c3c" />
          </linearGradient>
        </defs>
        <path
          className="gauge__background"
          d="M10 50 A 40 40 0 0 1 90 50"
          stroke="#333"
          strokeWidth="10"
          fill="none"
        />
        <path
          className="gauge__fill"
          d="M10 50 A 40 40 0 0 1 90 50"
          stroke={`url(#${gradientId})`}
          strokeWidth="10"
          fill="none"
          style={{ strokeDasharray: `${(value / 100000) * 126}, 126` }}
        />
        <line
          className="gauge__needle"
          x1="50"
          y1="50"
          x2="50"
          y2="10"
          stroke="#fff"
          strokeWidth="3"
          style={needleStyle}
        />
        <circle cx="50" cy="50" r="3" fill="#fff" />
        <text x="50" y="45" textAnchor="middle" fill="#fff" fontSize="5">
          {value} miles
        </text>
      </svg>
      <div className="gauge__label">{label}</div>
    </div>
  );
};

const VehicleEfficiency = ({ deviceId }) => {
  const [scores, setScores] = useState({
    engineRpm: 0,
    mileage: 0,
    speed: 0,
    fuelLevel: 0,
  });

  const fetchAndSetTelemetryData = useCallback(async () => {
    if (deviceId) {
      console.log(`Fetching telemetry data for device ID: ${deviceId}`);
      const telemetryData = await fetchTelemetryData(deviceId);

      if (telemetryData) {
        const updatedScores = {
          engineRpm: telemetryData['can.engine.rpm']?.value || 0,
          mileage: telemetryData['can.vehicle.mileage']?.value || 0,
          speed: telemetryData['can.vehicle.speed']?.value || 0,
          fuelLevel: telemetryData['can.fuel.level']?.value || telemetryData['can.fuel.volume']?.value || 0,
        };

        console.log('Updated scores:', updatedScores);
        setScores(updatedScores);
      }
    } else {
      console.warn('No device ID provided, cannot fetch telemetry data.');
    }
  }, [deviceId]);

  useEffect(() => {
    fetchAndSetTelemetryData();
  }, [fetchAndSetTelemetryData]);

  return (
    <div className="cardd vehicle-efficiency-container">
      <h2>Live Odometer</h2>
      <div className="gauges">
        <div className='gauge-container'>
        <Gauge value={scores.fuelLevel} label="Fuel Level" max={100} />
        </div>
        <div className='gauge-container'>
        <Gauge value={scores.engineRpm} label="Engine RPM" max={8000} />
        </div>
        <div className='gauge-container'>
        <Gauge value={scores.speed} label="Speed" max={240} />
        </div>
        <div className='gauge-container'>
        <MileageGauge value={scores.mileage} label="Mileage" />
        </div>
      </div>
    </div>
  );
};

export default React.memo(VehicleEfficiency);
