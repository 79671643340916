import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import axios from 'axios';
import './VehicleActivityLogs.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import productService from '../../redux/features/product/productService';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const REACT_APP_FLESPI_TOKEN = process.env.REACT_APP_FLESPI_TOKEN;

const VehicleActivityLogs = ({ doorStatus, deviceDetails, imei }) => {
  const [vehicleLogs, setVehicleLogs] = useState([]);
  const [previousSpeed, setPreviousSpeed] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const logIntervalRef = useRef(null);
  const latestLogRef = useRef({});
  const [telemetryData, setTelemetryData] = useState([]);

  const fetchTelemetryData = useCallback(async (deviceId) => {
    const maxRetries = 5;

    for (let i = 0; i < maxRetries; i++) {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/flespi/gw/devices/${deviceId}/telemetry/position,can.vehicle.mileage,can.engine.rpm,can.fuel.level,can.engine.ignition.status,can.handbrake.status,can.vehicle.speed,can.front.left.door.status`, {
          headers: {
            'Authorization': `FlespiToken ${REACT_APP_FLESPI_TOKEN}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.data && response.data.result && response.data.result.length > 0) {
          const telemetry = response.data.result[0].telemetry;
          setTelemetryData(telemetry);
          console.log('Fetched telemetry data:', telemetry);
          return;
        } else {
          console.warn(`No telemetry data found for device ID: ${deviceId}`);
        }
      } catch (error) {
        if (error.response && error.response.status === 429) {
          const retryAfter = error.response.headers['retry-after']
            ? parseInt(error.response.headers['retry-after']) * 1000
            : (2 ** i) * 1000;
          console.warn(`Rate limit exceeded. Retrying after ${retryAfter} ms...`);
          await new Promise(resolve => setTimeout(resolve, retryAfter));
        } else {
          console.error('Error fetching telemetry data:', error);
          break;
        }
      }
    }
  }, []);

  useEffect(() => {
    if (deviceDetails && deviceDetails.id) {
      console.log(`IMEI in VehicleActivityLogs: ${imei}`);
      fetchTelemetryData(deviceDetails.id);
    }
  }, [imei, deviceDetails, fetchTelemetryData]);

  const handleCloseDatePicker = () => {
    setShowDatePicker(false);
  };

  const fieldsToCheck = useMemo(() => [
    'action', 'location', 'engineAction', 'handbrake', 'doorStatus', 'engineRPM', 'odometer', 'vehicleSpeed', 'acceleration', 'vehicleIdling'
  ], []);

  // Updated function to detect and use the user's current timezone
  const formatTimestamp = (timestamp) => {
    let date;
  
    // Check if the timestamp is already a Date object, or try to convert it to one
    if (typeof timestamp === 'string') {
      // Try to create a Date from a string
      date = new Date(timestamp);
    } else if (timestamp instanceof Date) {
      date = timestamp; // Use it directly if already a Date object
    }
  
    // Handle invalid dates
    if (isNaN(date?.getTime())) {
      console.warn('Invalid timestamp:', timestamp); // Debugging to see invalid timestamps
      return 'Invalid time'; // Return a user-friendly message for invalid timestamps
    }
  
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's timezone
    return new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'short',
      timeStyle: 'medium',
      timeZone: userTimezone, // Use the detected timezone
    }).format(date);
  };
  
  
  

  const fetchDataAndUpdateLogs = useCallback(async () => {
    if (telemetryData && deviceDetails) {
      const latestData = telemetryData;
      console.log("Latest Data: ", latestData);
      const currentSpeed = parseInt(latestData['can.vehicle.speed']?.value || '0', 10);
      const acceleration = ((currentSpeed - previousSpeed) / 5).toFixed(2);

      setPreviousSpeed(currentSpeed);

      const engineStatus = latestData['can.engine.ignition.status']?.value;
      const handbrakeStatus = !!latestData['can.handbrake.status']?.value;  // Convert to Boolean
      const latitude = latestData['position']?.value.latitude;
      const longitude = latestData['position']?.value.longitude;
      const timestamp = new Date().toISOString(); // Generates a valid ISO timestamp
      const doorStatus = !!latestData['can.front.left.door.status']?.value; // Ensure it's a Boolean
      const engineRPM = latestData['can.engine.rpm']?.value?.toString();
      const odometer = latestData['can.vehicle.mileage']?.value?.toString();
      const vehicleSpeed = latestData['can.vehicle.speed']?.value?.toString();
      const vehicleIdling = engineStatus && vehicleSpeed === 0 ? 'Yes' : 'No';

      let action = engineStatus ? 'turned ON' : 'turned OFF';
      action = engineStatus === null ? 'IDLE' : action;

      let address = 'Fetching address...';
      try {
        // Ensure latitude and longitude are properly passed and not undefined
        if (typeof latitude === 'number' && typeof longitude === 'number' && !isNaN(latitude) && !isNaN(longitude)) {
          // Fetch the address using latitude and longitude
          try {
              address = await productService.fetchAddress(latitude, longitude);
          } catch (error) {
              console.error('Error fetching address:', error);
              address = 'Unable to fetch address';
          }
        } else {
          address = 'Invalid coordinates';
        }
      
      } catch (error) {
        console.error('Error fetching address:', error);
        address = 'Unable to fetch address';
      }

      const newLog = {
        vehicleId: deviceDetails.id,
        time: formatTimestamp(timestamp),
        action,
        location: address,
        engineAction: engineStatus ? 'ON' : 'OFF',
        handbrake: handbrakeStatus,  // Boolean value
        doorStatus: doorStatus,
        engineRPM: engineRPM || 'N/A',
        odometer: odometer || 'N/A',
        vehicleSpeed: vehicleSpeed || 'N/A',
        vehicleIdling,
        acceleration: isNaN(acceleration) ? '0' : acceleration,  // Ensure acceleration is provided
      };

      const fieldsChanged = fieldsToCheck.some(field => {
        if (typeof newLog[field] === 'object' && typeof latestLogRef.current[field] === 'object') {
          return JSON.stringify(newLog[field]) !== JSON.stringify(latestLogRef.current[field]);
        }
        return newLog[field] !== latestLogRef.current[field];
      });

      if (fieldsChanged) {
        console.log('New log differs from previous log, saving new log:', newLog);
        setVehicleLogs([newLog]);
        latestLogRef.current = newLog;

        // try {
        //   await axios.post(`${BACKEND_URL}/vehicle-activity-log`, newLog);
        // } catch (error) {
        //   if (error.response) {
        //     // The request was made, and the server responded with a status code outside of the 2xx range
        //     console.error('Server response error:', error.response.data);
        //   } else if (error.request) {
        //     // The request was made, but no response was received
        //     console.error('No response received:', error.request);
        //   }        }
      } else {
        console.log('No relevant changes detected, not saving log');
      }
    }
  }, [telemetryData, previousSpeed, fieldsToCheck, deviceDetails]);

  useEffect(() => {
    logIntervalRef.current = setInterval(fetchDataAndUpdateLogs, 60000);
    fetchDataAndUpdateLogs();

    return () => clearInterval(logIntervalRef.current);
  }, [fetchDataAndUpdateLogs]);

  const handleGenerateReport = async () => {
    setShowDatePicker(false);
    if (!startDate || !endDate) {
        alert('Please select a valid date range.');
        return;
    }

    const start = startDate.toISOString().split('T')[0];
    const end = endDate.toISOString().split('T')[0];
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dateArray.push(new Date(currentDate).toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    const allLogs = [];
    try {
        for (const date of dateArray) {
            const response = await axios.get(`${BACKEND_URL}/vehicle-activity-log?date=${date}&vehicleId=${deviceDetails.id}`);
            if (response.data.length > 0) {
                allLogs.push(...response.data);
            }
        }

        if (allLogs.length === 0) {
            alert('There are no logs found for this date range');
            return;
        }

        const doc = new jsPDF();

        // Set title
        doc.text(`Vehicle Activity Logs from ${start} to ${end}`, 10, 10);
        
        // Define the table headers
        const headers = [
            ['Timestamp', 'Address', 'Engine Status', 'Handbrake', 'Door Status', 'Engine RPM', 'Mileage', 'Speed', 'Idling']
        ];

        // Process the data into a format that fits the table
        const tableData = allLogs.map(log => [
          formatTimestamp(log.timestamp), // Apply dynamic time formatting
          log.location,
            log.engineAction,
            log.handbrake ? 'Engaged' : 'Released',
            log.doorStatus ? 'Opened' : 'Closed',
            log.engineRPM,
            log.odometer,
            log.vehicleSpeed,
            log.vehicleIdling
        ]);

        // Add table to the PDF
        doc.autoTable({
            head: headers,
            body: tableData,
            startY: 20, // Ensure the table starts after the title
            styles: {
                fontSize: 8
            },
            theme: 'striped' // Optional styling for the table
        });

        doc.save(`Vehicle_Activity_Logs_${deviceDetails.id}_${start}_to_${end}.pdf`);
    } catch (error) {
        console.error('Error generating report:', error);
    }
  };

  return (
    <div className="vehicle-activity-logs">
      <h2>Vehicle Activity Logs    <br/>     <button className='datePickerButton' onClick={() => setShowDatePicker(true)}>Generate Report</button>      </h2>
      {showDatePicker && (
        <div className="datePickerWrapper">
          <DatePicker
            selected={startDate}
            onChange={(dates) => {
              const [start, end] = dates;
              setStartDate(start);
              setEndDate(end);
            }}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
          <button className='generateReportButton' onClick={handleGenerateReport}>Generate Report</button>
          <button className='closeDatePickerButton' onClick={handleCloseDatePicker}>&times;</button>
        </div>
      )}
      <ul className="vehicle-activity-log-list">
        {vehicleLogs.map((log, index) => (
          <div key={index} className="log-entry">
            <ul>
              <p className='log-item'>
                {log.time} - Vehicle is currently at {log.location}
              </p>
              <p className={log.engineAction === 'OFF' ? 'engine-off' : 'engine-on'}>
                {log.time} - Engine Status: {log.engineAction}
              </p>
              <p className='log-item'>
                {log.time} - Handbrake Status: {log.handbrake ? 'Engaged' : 'Released'}
              </p>
              <p className={log.doorStatus ? 'door-opened' : 'door-closed'}>
                {log.time} - Driver's Door Status: {log.doorStatus ? 'Opened' : 'Closed'}
              </p>
              <p className='log-item'>{log.time} - Engine RPM: {log.engineRPM}</p>
              <p className='log-item'>{log.time} - Odometer Reading: {log.odometer}</p>
              <p className='log-item'>{log.time} - Vehicle Speed: {log.vehicleSpeed}</p>
              <p className={log.vehicleIdling === 'Yes' ? 'vehicle-idling' : 'vehicle-not-idling'}>
                {log.time} - Vehicle Idling: {log.vehicleIdling}
              </p>          
            </ul>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default React.memo(VehicleActivityLogs);
