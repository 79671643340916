const initialState = {
  telemetryData: {},
  loading: false,
  error: null,
};

function vehicleTelemetryReducer(state = initialState, action) {
  switch (action.type) {
    case 'vehicleTelemetry/fetchStart':
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'vehicleTelemetry/fetchSuccess':
      const newTelemetryData = {};
      if (Array.isArray(action.payload)) {
        action.payload.forEach(vehicle => {
          newTelemetryData[vehicle.ident] = vehicle;
        });
      }
      return {
        ...state,
        telemetryData: newTelemetryData,
        loading: false,
      };
    case 'vehicleTelemetry/fetchFailure':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case 'vehicleTelemetry/setTelemetryDataForDevice':
      const { deviceId, data } = action.payload;
      return {
        ...state,
        telemetryData: {
          ...state.telemetryData,
          [deviceId]: data,
        },
      };
    default:
      return state;
  }
}

export default vehicleTelemetryReducer;
