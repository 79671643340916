import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AssignVehicles.scss';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const REACT_APP_FLESPI_TOKEN = process.env.REACT_APP_FLESPI_TOKEN;

const AssignVehicles = ({ userId, onClose, onUserUpdated }) => {
  const [availableDevices, setAvailableDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [userDevices, setUserDevices] = useState([]);

  const fetchDeviceDetails = async (deviceId) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/flespi/gw/devices/${deviceId}`, {
        headers: {
          'Authorization': `FlespiToken ${REACT_APP_FLESPI_TOKEN}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data.result[0];
    } catch (error) {
      console.error('Error fetching device details:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchUserDevices = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/users/${userId}`);
        setUserDevices(response.data.assignedDevices || []);
      } catch (error) {
        console.error('Failed to fetch user devices', error);
      }
    };

    const fetchAllDevices = async () => {
      try {
        const deviceIds = [5812973, 5850881, 5819862, 5850580, 5851101, 5870018, 5870272,
          5880168, 5880170, 5881262, 5882831, 5882911, 5886022, 5896298, 
          5896299, 5954456, 5964236, 5980883, 5983450, 5991154, 5992506,
          5992512, 5992514, 5992518, 5992522, 5992523, 5992527, 5992530,
          5992531, 5992533, 5992569, 6004115, 6004458, 6006149, 6006150, 6006173,
          6011942, 6012146, 6014117, 6016642, 6016644, 6018398, 6018402,
          6022452, 6022708, 6025021, 6025084, 6029459, 6029464, 6029476, 6029503,
          6029544, 6029553, 6029557, 6029576, 6037850, 6037863, 6037867,
          6038279, 6043264, 6043267, 6043268, 6043270, 6043271, 6043314,
          6043403, 6049583, 6056187, 6056192, 6056194, 6061832, 6061840, 
          6061844, 6061860, 6061864, 6061865, 6061867, 6061870, 6064683,
          6064685, 6064688, 6064690, 6064697, 6064700
          ];
        const devices = await Promise.all(deviceIds.map(fetchDeviceDetails));
        const unassignedDevices = devices.filter(
          (device) => device !== null && !userDevices.some((userDevice) => userDevice.includes(device.id))
        );
        setAvailableDevices(unassignedDevices);
      } catch (error) {
        console.error('Failed to fetch all devices', error);
      }
    };

    fetchUserDevices().then(fetchAllDevices);
  }, [userId, userDevices]);

  const handleDeviceChange = (e) => {
    const deviceId = Number(e.target.value);
    const { checked } = e.target;
    const deviceName = availableDevices.find((device) => device.id === deviceId)?.name;

    if (deviceName) {
      const deviceString = `${deviceName}/${deviceId}`;
      setSelectedDevices((prevDevices) =>
        checked ? [...prevDevices, deviceString] : prevDevices.filter((device) => device !== deviceString)
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(`${BACKEND_URL}/api/users/${userId}`, { newDevices: selectedDevices });
      onUserUpdated(response.data);
      onClose();
    } catch (error) {
      console.error('Failed to assign devices', error);
    }
  };

  return (
    <div className="assign-vehicles-form">
      <h2>Assign Vehicles to User</h2>
      <form onSubmit={handleSubmit}>
        <div className="device-list">
          {availableDevices.map((device) => (
            <label key={device.id}>
              <input
                type="checkbox"
                value={device.id}
                checked={selectedDevices.includes(`${device.name}/${device.id}`)}
                onChange={handleDeviceChange}
              />
              {device.name}
            </label>
          ))}
        </div>
        <button type="submit">Assign Devices</button>
        <button type="button" onClick={onClose}>Cancel</button>
      </form>
    </div>
  );
};

export default AssignVehicles;
