import mqtt from 'mqtt';
import { useState, useEffect } from 'react';

const useMqttService = (token) => {
  const [vehicleData, setVehicleData] = useState({});
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const client = mqtt.connect('wss://mqtt.flespi.io:443', {
      username: token,
      clientId: `web_${Math.random().toString(16).substr(2, 8)}`,
      clean: true
    });

    client.on('connect', () => {
      setIsConnected(true);
      console.log('Connected to MQTT broker');
      const deviceIds = [5812973, 5850881, 5819862, 5850580, 5851101, 5870018, 5870272,
        5880168, 5880170, 5881262, 5882831, 5882911, 5886022, 5896298, 
        5896299, 5954456, 5964236, 5980883, 5983450, 5991154, 5992506,
        5992512, 5992514, 5992518, 5992522, 5992523, 5992527, 5992530,
        5992531, 5992533, 5992569, 6004115, 6004458, 6006149, 6006150, 6006173,
        6011942, 6012146, 6014117, 6016642, 6016644, 6018398, 6018402,
        6022452, 6022708, 6025021, 6025084, 6029459, 6029464, 6029476, 6029503,
        6029544, 6029553, 6029557, 6029576, 6037850, 6037863, 6037867,
        6038279, 6043264, 6043267, 6043268, 6043270, 6043271, 6043314,
        6043403, 6049583, 6056187, 6056192, 6056194, 6061832, 6061840, 
        6061844, 6061860, 6061864, 6061865, 6061867, 6061870, 6064683,
        6064685, 6064688, 6064690, 6064697, 6064700];
      deviceIds.forEach(deviceId => {
        client.subscribe(`flespi/state/gw/devices/${deviceId}/telemetry/+`);
      });
    });

    client.on('message', (topic, message) => {
      const payload = JSON.parse(message.toString());
      const topicParts = topic.split('/');
      const deviceId = topicParts[4];
      const param = topicParts[6];

      setVehicleData(prevData => ({
        ...prevData,
        [deviceId]: {
          ...prevData[deviceId],
          [param]: payload
        }
      }));
    });

    return () => {
      if (client) {
        client.end();
      }
    };
  }, [token]); // Ensure reconnection on token change

  return { vehicleData, isConnected };
};

export default useMqttService;
