import React from 'react';
import useMqttService from '../pages/MqttService'; // Adjust the path as necessary

const MqttDataDisplay = ({ token }) => {
  const { vehicleData, isConnected } = useMqttService(token);

  return (
    <div>
      <h1>MQTT Connection Status: {isConnected ? "Connected" : "Disconnected"}</h1>
      <h2>Vehicle Telemetry Data:</h2>
      <div>
        {Object.entries(vehicleData).map(([deviceId, data]) => (
          <div key={deviceId}>
            <h3>Device {deviceId}</h3>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MqttDataDisplay;
